import React from "react"
import Layout from "../../components/layout"
import Card from 'react-bootstrap/Card'
import Button from 'react-bootstrap/Button'
import Accordion from 'react-bootstrap/Accordion'
import ListGroup from 'react-bootstrap/ListGroup'
import FontIcon from '../../components/fonticon'

export default ({data}) => (
<div style={{backgroundColor:"#f9fafe"}}>
  <Layout>
  <div className="headerShadow"></div>
    <div className="container p-4">
      <h2>HTML</h2>
      <div className="row">
        <div className="col-md-6 col-lg-6 col-xl-6">
          <div style={{maxWidth:"500px"}}>
            <Card style={{border:"none"}}>
              <Card.Img style={{borderRadius:"10px"}} variant="top" src="../../images/courses/html.svg" />
              <Card.Body className="pb-0 pl-0" style={{backgroundColor:"#f9fafe"}}>
                <p>Few quick example text to build on the card title and make up the bulk of the card's content. Few quick example text to build on the card title and make up the bulk of the card's content. Few quick example text to build on the card title and make up the bulk of the card's content.</p>
              </Card.Body>
            </Card>
          </div>
        </div>
        <div className="col-md-6 col-lg-6 col-xl-6 pl-4 pr-4">
          <Accordion>
            <Card className="cardShadow">
              <Card.Header className="cramCourseHeader">
                <Accordion.Toggle as={Button} variant="link" eventKey="1" className="cramCourseAccordion">
                  <h5>Introduction to HTML</h5>
                  <hr />
                  <p style={{paddingLeft: "0px",fontSize: "16px"}}>
                  Learn the basic structure of a webpage, tags, and styles in HTML. Learn the basic structure of a webpage, tags, and styles in HTML.
                  <br />
                  <button type="button" className="btn" style={{padding: "0px",color: "#007bff",fontSize: "14px",marginTop: "4px"}}>view more</button></p>
                </Accordion.Toggle>
              </Card.Header>
              <Accordion.Collapse eventKey="1">
                <Card.Body className="p-0">
                  <ListGroup>
                    <ListGroup.Item className="p-l-2 p-t-4 p-b-4 cram-course-list-group-item"><FontIcon tag="faPlay" />Introduction to HTML</ListGroup.Item>
                    <ListGroup.Item className="p-l-2 p-t-4 p-b-4 cram-course-list-group-item"><FontIcon tag="faPlay" />Browsers and HTML</ListGroup.Item>
                    <ListGroup.Item className="p-l-2 p-t-4 p-b-4 cram-course-list-group-item"><FontIcon tag="faPlay" />Editor's Offline and Online</ListGroup.Item>
                    <ListGroup.Item className="p-l-2 p-t-4 p-b-4 cram-course-list-group-item"><FontIcon tag="faPlay" />Tags, Attribute and Elements</ListGroup.Item>
                  </ListGroup>
                </Card.Body>
              </Accordion.Collapse>
            </Card>
              <Card className="cardShadow">
                <Card.Header className="cramCourseHeader">
                  <Accordion.Toggle as={Button} variant="link" eventKey="2" className="cramCourseAccordion">
                    <h5>Browsers and HTML</h5>
                    <hr />
                    <p style={{paddingLeft: "0px",fontSize: "16px"}}>
                    Learn the basic structure of a webpage, tags, and styles in HTML. Learn the basic structure of a webpage, tags, and styles in HTML.
                    <br />
                    <button type="button" className="btn btn-link" style={{padding: "0px",color: "#007bff",fontSize: "14px",marginTop: "4px"}}>view more</button></p>
                  </Accordion.Toggle>
                </Card.Header>
                <Accordion.Collapse eventKey="2">
                  <Card.Body className="p-0">
                    <ListGroup>
                      <ListGroup.Item className="p-l-2 p-t-4 p-b-4 cram-course-list-group-item"><FontIcon tag="faPlay" />Introduction to HTML</ListGroup.Item>
                      <ListGroup.Item className="p-l-2 p-t-4 p-b-4 cram-course-list-group-item"><FontIcon tag="faPlay" />Browsers and HTML</ListGroup.Item>
                      <ListGroup.Item className="p-l-2 p-t-4 p-b-4 cram-course-list-group-item"><FontIcon tag="faPlay" />Editor's Offline and Online</ListGroup.Item>
                      <ListGroup.Item className="p-l-2 p-t-4 p-b-4 cram-course-list-group-item"><FontIcon tag="faPlay" />Tags, Attribute and Elements</ListGroup.Item>
                    </ListGroup>
                  </Card.Body>
                </Accordion.Collapse>
              </Card>
                <Card className="cardShadow">
                  <Card.Header className="cramCourseHeader">
                    <Accordion.Toggle as={Button} variant="link" eventKey="3" className="cramCourseAccordion">
                      <h5>Editor's Offline and Online</h5>
                      <hr />
                      <p style={{paddingLeft: "0px",fontSize: "16px"}}>
                      Learn the basic structure of a webpage, tags, and styles in HTML. Learn the basic structure of a webpage, tags, and styles in HTML.
                      <br />
                      <button type="button" className="btn btn-link" style={{padding: "0px",color: "#007bff",fontSize: "14px",marginTop: "4px"}}>view more</button></p>
                    </Accordion.Toggle>
                  </Card.Header>
                  <Accordion.Collapse eventKey="3">
                    <Card.Body className="p-0">
                      <ListGroup>
                        <ListGroup.Item className="p-l-2 p-t-4 p-b-4 cram-course-list-group-item"><FontIcon tag="faPlay" />Introduction to HTML</ListGroup.Item>
                        <ListGroup.Item className="p-l-2 p-t-4 p-b-4 cram-course-list-group-item"><FontIcon tag="faPlay" />Browsers and HTML</ListGroup.Item>
                        <ListGroup.Item className="p-l-2 p-t-4 p-b-4 cram-course-list-group-item"><FontIcon tag="faPlay" />Editor's Offline and Online</ListGroup.Item>
                        <ListGroup.Item className="p-l-2 p-t-4 p-b-4 cram-course-list-group-item"><FontIcon tag="faPlay" />Tags, Attribute and Elements</ListGroup.Item>
                      </ListGroup>
                    </Card.Body>
                  </Accordion.Collapse>
                </Card>
          </Accordion>
        </div>
      </div>
    </div>
  </Layout>
</div>
)
